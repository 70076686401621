<div id="login-block" class="ui-g-12 ui-md-12" *ngIf="!_authService.isLoggedIn">
    <div class="ui-g-12 ui-md-6 textLogin-block">
        <div id="login-box-text">
            <h1>ORGANIZE E
            <span>SIMPLIFIQUE</span>
            </h1>
            <h2>PARA ALCANÇAR UMA GESTÃO DE SUCESSO!</h2>
        </div>
    </div>
    <div class="ui-g-12 ui-md-6 boxLogin-block">
        <div class="login-box">
            <div class="login-logo">
                <img src="../assets/img/logo/pequeno/logo.png" class="img-responsive"/>
            </div> 
            <!-- /.login-logo -->
            <form (ngSubmit)="logar()" class="login-box-body">
                <p class="login-box-msg">Entrar com:</p>
                <div class="form-group has-feedback form-login">
                    <input class="form-control form-login" placeholder="e-mail" name="login" type="text" autofocus [(ngModel)]="loginModel.login"/>
                    <span class="glyphicon glyphicon-user form-control-feedback"></span>
                </div>
                <div class="form-group has-feedback form-login">
                    <input class="form-control form-login" placeholder="senha" name="password" type="password" [(ngModel)]="loginModel.password"/>
                    <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <button type="submit" id="btn-login-access" class="btn btn-primary btn-block btn-flat">Entrar</button>
                    </div>
                    <div class="col-xs-8">
                        <div class="checkbox icheck">
                            <label>
                            <input type="checkbox"> Lembrar de mim
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="logo-araguaia">
                            <img src="../assets/img/logo-araguaia-sistemas.png" class="img-responsive"/>
                        </div>
                    </div>
                </div>
                <!--
                <div class="social-auth-links text-center">
                    <p>- ou -</p>
                    <a href="#" class="btn btn-block btn-social btn-facebook btn-flat">
                        <i class="fa fa-facebook"></i> Entrar com Facebook</a>
                    <a href="#" class="btn btn-block btn-social btn-google btn-flat">
                        <i class="fa fa-google-plus"></i> Entrar com o Google+</a>
                </div>
                <a href="#">Esqueceu a senha</a>
                <a href="#" class="text-center">Novo Usuário </a>-->
            </form>
        </div>
        <hr class="login-hr">
        <div class="login-reset">
            <p>Esqueceu sua senha ?</p>
            <a href="#">Sem estresse, clique aqui para conseguir outra.</a>
        </div>
    </div>
</div>
export const TipoCodigoProduto = {
    
      CODIGO: 1 as 1,
      CODIGO_BARRA: 2 as 2,
      CODIGO_REFERENCIA: 3 as 3,
      CODIGO_ORIGINAL: 4 as 4,
      OUTRO_CODIGO: 5 as 5,
      CODIGO_BARRA_PRECO: 6 as 6,
    
    }
    
    
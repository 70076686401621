import { Injectable } from '@angular/core';

@Injectable()
export class SelecaoDataTableService {

    constructor() { }

    onKeydownDiv(event) {
       /* KeyCode = seta para baixo */ 
       if(event.keyCode === 40) {
           if (document.getElementById('irmao')){
               if(event.target.parentElement.parentElement.nextElementSibling){
                   document.getElementById('irmao').style.backgroundColor = "";
                   document.getElementById('primo').id = ""; 
                   document.getElementById('irmao').id = "";
                                
                   event.target.parentElement.parentElement.nextElementSibling.id ='irmao';
                   let a = event.target.parentElement.parentElement.nextElementSibling.children[0];
                   let b = a.children[0];
                   b.children[0].id = 'primo';
                   document.getElementById('primo').focus();
                   event.target.parentElement.parentElement.nextElementSibling.style.backgroundColor = "rgba(189, 188, 188, 0.62)";
               }
                            
           }else{
              event.target.parentElement.parentElement.id = 'irmao';              
              event.target.parentElement.parentElement.style.backgroundColor = "rgba(189, 188, 188, 0.62)";

              let a = event.target.children[0].id = 'primo';
              document.getElementById('primo').focus();
           } 
       }
       /* KeyCode = seta para a cima */
       if(event.keyCode === 38) {
            
            if (document.getElementById('irmao') && document.getElementById('primo')){                              
                
                if(event.target.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('primo').id = ""; 
                    document.getElementById('irmao').id = "";

                    event.target.parentElement.parentElement.previousElementSibling.id ='irmao';
                    let a = event.target.parentElement.parentElement.previousElementSibling.children[0];
                    let b = a.children[0];
                    b.children[0].id = 'primo';
                    document.getElementById('primo').focus();
                    event.target.parentElement.parentElement.previousElementSibling.style.backgroundColor = "rgba(189, 188, 188, 0.62)";
                }
            
           }else{
              event.target.parentElement.parentElement.id = 'irmao';              
              event.target.parentElement.parentElement.style.backgroundColor = "rgba(189, 188, 188, 0.62)";

              let a = event.target.children[0].id = 'primo';
              document.getElementById('primo').focus();
           }      
       }
       /* KeyCode = Shift + TAB */
       if(event.shiftKey == true && event.keyCode === 9){
           
           if (document.getElementById('irmao') && document.getElementById('primo')){                              
                
                if(event.target.parentElement.parentElement.previousElementSibling){
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('primo').id = ""; 
                    document.getElementById('irmao').id = "";

                    event.target.parentElement.parentElement.previousElementSibling.id ='irmao';
                    let a = event.target.parentElement.parentElement.previousElementSibling.children[0];
                    let b = a.children[0];
                    b.children[0].id = 'primo';
                    event.target.parentElement.parentElement.previousElementSibling.style.backgroundColor = "rgba(189, 188, 188, 0.62)";
                }else{
                    document.getElementById('irmao').style.backgroundColor = "";
                    document.getElementById('primo').id = ""; 
                    document.getElementById('irmao').id = "";
                }
            
           }else{
              event.target.parentElement.parentElement.previousElementSibling.id = 'irmao';              
              event.target.parentElement.parentElement.previousElementSibling.style.backgroundColor = "rgba(189, 188, 188, 0.62)";

              let a = event.target.children[0].id = 'primo';
              document.getElementById('primo').focus();
           }     
           return;
       }
       /* KeyCode = TAB */
       if(event.keyCode === 9){
            if (document.getElementById('irmao')){
                document.getElementById('irmao').style.backgroundColor = "";
                document.getElementById('primo').id = ""; 
                document.getElementById('irmao').id = "";

                event.target.parentElement.parentElement.nextElementSibling.id = 'irmao';              
                event.target.parentElement.parentElement.nextElementSibling.style.backgroundColor = "rgba(189, 188, 188, 0.62)";

                let a = event.target.parentElement.parentElement.nextElementSibling.children[0];
                let b = a.children[0];
                b.children[0].id = 'primo';
           
            }else{
                event.target.parentElement.parentElement.nextElementSibling.id = 'irmao';              
                event.target.parentElement.parentElement.nextElementSibling.style.backgroundColor = "rgba(189, 188, 188, 0.62)";

                let a = event.target.children[0].id = 'primo';
                document.getElementById('primo').focus();
            }
       }
       /* KeyCode = seta para a direita */
       if(event.keyCode === 39){
           
           let paginator = document.getElementsByClassName('ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active');
           
           if(paginator[0].nextElementSibling){
               paginator[0].nextElementSibling.id = 'paginator';
               document.getElementById('paginator').click();
               paginator[0].nextElementSibling.id = '';
           }
       }
       /* KeyCode = seta para a esquerda */
       if(event.keyCode === 37){
           
           let paginator = document.getElementsByClassName('ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active');
           
           if(paginator[0].previousElementSibling){
               paginator[0].previousElementSibling.id = 'paginator';
               document.getElementById('paginator').click();
               paginator[0].previousElementSibling.id = '';
           }
       }
    }
}
<div id="containerBreadcrumb">
  <section class="content-header">
    <div class="icon-breadcrumb">
      <i class="fi-dashboard-graphic"></i>
    </div>
    <h1>
      Dashboard
      <small>{{version}}</small>
    </h1>
    <ol class="breadcrumb">
      <li>
        <form [formGroup]="filtro" class="dashboard-date-filter">
          <p class="data"> Período: </p>
          <p-calendar class="calendar-filter" formControlName="periodo" selectionMode="range" dateFormat="dd/mm/yy"
            (onSelect)="onSelectDate()" [locale]="pt_BR" required>
          </p-calendar>
        </form>
      </li>
      <li><a href="#"> Home</a></li>
      <li class="active">Dashboard</li>
    </ol>
  </section>
</div>
<div class="row dashboard-panels">
  <div *ngIf="globalsVariablesService.getPermissoes.visualizarDashboard">
    <!-- ./col -->
    <div class="ui-sm-6 ui-md-6 ui-lg-3">
      <!-- small box -->
      <div class="small-box bg-yellow">
        <div class="inner">
          <h3>{{valorVendas | number:'1.2-2'}}</h3>
          <p>Vendas</p>
        </div>
        <div class="icon">
          <i class="fi-ecommerce-shopping-basket"></i>
        </div>
        <a routerLink="/relatorios/rel-vendas" class="small-box-footer">Mais informações <i
            class="fi-interface-right-arrow"></i></a>
      </div>
    </div>
    <!-- ./col -->
    <div class="ui-sm-6 ui-md-6 ui-lg-3">
      <!-- small box -->
      <div class="small-box bg-green">
        <div class="inner">
          <h3>{{valorContasAReceber | number:'1.2-2'}} </h3>
          <p>A Receber</p>
        </div>
        <div class="icon">
          <i class="fi-ecommerce-cash"></i>
        </div>
        <a routerLink="/relatorios/rel-duplicata-receber" class="small-box-footer">Mais informações <i
            class="fi-interface-right-arrow"></i></a>
      </div>
    </div>
    <!-- ./col -->
    <div class="ui-sm-6 ui-md-6 ui-lg-3">
      <!-- small box -->
      <div class="small-box bg-red">
        <div class="inner">
          <h3>{{valorContasAPagar | number:'1.2-2'}}</h3>
          <p>A Pagar</p>
        </div>
        <div class="icon">
          <i class="fi-ecommerce-coins"></i>
        </div>
        <a routerLink="/relatorios/rel-duplicata-pagar" class="small-box-footer">Mais informações <i
            class="fi-interface-right-arrow"></i></a>
      </div>
    </div>
    <div class="ui-sm-6 ui-md-6 ui-lg-3">
      <!-- small box -->
      <div class="small-box bg-aqua">
        <div class="inner">
          <h3>{{valorCompras | number:'1.2-2'}}</h3>
          <p>Compras</p>
        </div>
        <div class="icon">
          <i class="fi-dashboard-pie-graphic"></i>
        </div>
        <a routerLink="/relatorios/rel-compra" class="small-box-footer">Mais informações <i
            class="fi-interface-right-arrow"></i></a>
      </div>
    </div>
  </div>
  <section class="ui-sm-12 ui-md-12 ui-lg-12 connectedSortable ui-sortable">


    <p-card class="ui-sm-6 ui-md-2 ui-lg-2">
      <a class="rota" routerLink="/administrativo/loja">
        <div class="icon">
          <p>Loja</p>
          <img src="../assets/img/loja.png" class="img-responsive" />
        </div>
      </a>

    </p-card>
    <p-card class="ui-sm-6 ui-md-2 ui-lg-2">
      <a class="rota" routerLink="/administrativo/favorecido">
        <div class="icon">
          <p>Favorecido</p>
          <img src="../assets/img/favorecido.png" class="img-responsive" />
        </div>
      </a>

    </p-card>
    <p-card class="ui-sm-6 ui-md-2 ui-lg-2">
      <a class="rota" routerLink="/estoque/produto">
        <div class="icon">
          <p>Produto</p>
          <img src="../assets/img/produtos.png" class="img-responsive" />
        </div>
      </a>
    </p-card>
    <p-card class="ui-sm-6 ui-md-2 ui-lg-2">
      <a class="rota" routerLink="/comercial/pedido">
        <div class="icon">
          <p>Pedido</p>
          <img src="../assets/img/vendas.png" class="img-responsive" />
        </div>
      </a>
    </p-card>
    <p-card class="ui-sm-6 ui-md-2 ui-lg-2">
      <a class="rota" routerLink="/comercial/importacao-compra">
        <div class="icon">
          <p>Importação de compra</p>
          <img src="../assets/img/compras.png" class="img-responsive" />
        </div>
      </a>
    </p-card>
    <p-card class="ui-sm-6 ui-md-2 ui-lg-2">
      <a class="rota" routerLink="/relatorios/rel-vendas">
        <div class="icon">
          <p>Relatório de vendas</p>
          <img src="../assets/img/relatorio.png" class="img-responsive" />
        </div>
      </a>
    </p-card>

  </section>
  <section>

  </section>
  <section class="ui-sm-12 ui-md-12 ui-lg-12 connectedSortable ui-sortable"
    style="display: flex; justify-content: space-around;">
    <div class="ui-sm-6 ui-md-6 ui-lg-7" style="    flex-grow: 3;
                      background: #fff;
                      margin: 20px 15px 0px 5px;
                      box-shadow: -1px 2px 5px 6px #e9e7e7;
                      padding: 30px 0px 30px 0px;" class="sombraCardDashboard">
      <p-chart type="pie" [data]="dataGrafico" [width]="350" height="350"></p-chart>
    </div>

    <div class="ui-sm-6 ui-md-6 ui-lg-5" style="flex-grow: 9; margin-top: 20px">
      <div style="background-color: #fff; ; padding: 30px;" class="sombraCardDashboard">
        <div style="text-align: center;">

          <ng-template #loading> </ng-template>
          <span style="font-size: 18pt;">Avisos!</span>
        </div>
        <div *ngIf="diasRestanteLicenca | async as result else loading">
          <span style="color: red" *ngIf="result > 0 && result < 11">{{diasRestanteLicenca | async}} dia(s) para
            expirar a licença do programa Neon</span>
        </div>
        <div *ngIf="mensagemCertificado != null">
          <p>{{ mensagemCertificado }} </p>
        </div>
      </div>

    </div>



  </section>



  <section class="ui-sm-12 ui-md-12 ui-lg-6  connectedSortable ui-sortable">

    <!--
     Graphic Compras
        <div class="graphs-custom box box-solid bg-teal-gradient">
          <div class="box-header ui-sortable-handle">
            <i class="fi-interface-password"></i>
            <h3 class="box-title"> Demonstrativo de Compras</h3>
          </div>
          <div class="box-body border-radius-none">
            <p-chart type="line" [data]="compras"></p-chart>
          </div>
        </div>
   
    <div class="graphs-custom box box-warning">
      <div class="box-header ui-sortable-handle" >
        <i class="fi-ecommerce-like-1"></i>
        <h3 class="box-title">Últimas Notificações</h3>
      </div>
        <div class="box-body">
          <ul class="todo-list ui-sortable">
            <li class="" style="">
              <span class="handle ui-sortable-handle">
                <i class="fa fa-ellipsis-v"></i>
                <i class="fa fa-ellipsis-v"></i>
              </span>
              <span class="text">Compra de Peça Automotiva realizada!</span>
              <small class="label label-danger"><i class="fa fa-clock-o"></i> 2 min</small>
              <div class="tools">
                <i class="fa fa-edit"></i>
                <i class="fa fa-trash-o"></i>
              </div>
            </li>
            <li>
              <span class="handle ui-sortable-handle">
                <i class="fa fa-ellipsis-v"></i>
                <i class="fa fa-ellipsis-v"></i>
              </span>
              <span class="text">Preparar produto para entrega!</span>
              <small class="label label-info"><i class="fa fa-clock-o"></i> 4 horas</small>
              <div class="tools">
                <i class="fa fa-edit"></i>
                <i class="fa fa-trash-o"></i>
              </div>
            </li>
            <li>
              <span class="handle ui-sortable-handle">
                <i class="fa fa-ellipsis-v"></i>
                <i class="fa fa-ellipsis-v"></i>
              </span>
              <span class="text">Realizar pedido ao Fornecedor de peças</span>
              <small class="label label-success"><i class="fa fa-clock-o"></i> 3 dia(s)</small>
              <div class="tools">
                <i class="fa fa-edit"></i>
                <i class="fa fa-trash-o"></i>
              </div>
            </li>
            <li>
              <span class="handle ui-sortable-handle">
                <i class="fa fa-ellipsis-v"></i>
                <i class="fa fa-ellipsis-v"></i>
              </span>
              <span class="text">Atualizar Estoque</span>
              <small class="label label-default"><i class="fa fa-clock-o"></i> 10 dia(s)</small>
              <div class="tools">
                <i class="fa fa-edit"></i>
                <i class="fa fa-trash-o"></i>
              </div>
            </li>
          </ul>
        </div>
      <div class="box-footer clearfix no-border">
        <button type="button" class="btn btn-default bg-aqua pull-right"><i class="fa fa-plus"></i> Adicionar Notificação</button>
      </div>
    </div>
-->

  </section>
</div>

import {map} from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { PageResult, GenericService, SearchParams } from './generic.service';
import { GenericModel } from '../model/generic.model';
import { MessageService } from './message.service';

import { HttpClient } from '@angular/common/http';

/**
 * @author Felipe Leonhardt
 * http://www.vision-ti.com.br
 */
export abstract class VsCrudService<T extends GenericModel> extends GenericService {

    public url: string;
    pageResult: PageResult = new PageResult();
    qtdeRegistros:number=10;

    constructor(_http: HttpClient, _message: MessageService) {
        super(_http, _message);
    }

    /**
     * Save entity
     */
    public save(model: T): Observable<any> {
        return this.post(this.url, model);
    }

     /**
     * Save entity
     */
    public savePromisse(model: T): Promise<any> {
        return this.postPromisse(this.url, model);
    }

    /**
     * Remove entity
     */
    public remove(id: number) {
        return this.delete(this.url, id);
    }

        /**
     * Remove entity
     */
    public inativar(id: number) {
        return this.inactivate(this.url, id);
    }

    /**
     * Finde one entity
     */
    public findOne(id: number) {
        return this.get(`${this.url}/${id}`).pipe(map(res => <T>res));        
    }


   /**
     * Listar varios registros
     */
    public list(search: SearchParams) {
        
        let self = this;
        return this.search(this.url + '/search', search)
            .subscribe(res => {
                self.pageResult = res;
            });
    }

  /*  public listAll(id: number) {
        let resultado = [];
        return this.listAllByID(this.url + '/listarAllByID/' +  {id} ).then(
                dados => resultado = dados
        );        
    }
*/

}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from '../shared/service/message.service';
import { VsCrudService } from '../shared/service/vs-crud.service';
import { Dashboard } from './dashboard.model';




@Injectable()
export class DashboardService extends VsCrudService<Dashboard> {
  
    public url: string = "api/dashboard";

    public urlBloqueioController: string = "api/bloqueio"

    constructor(_http: HttpClient, _message: MessageService) { 
        super(<any>_http, _message);
    }
   
    public totalVendasRealizadas(lojaId: Number, periodo: Array<String>) {        
        return this.get(`${this.url}/totalVendasRealizadas/${lojaId}/${periodo}`).toPromise().then(res => 
              <Dashboard>res);     
    }

    public findDiasRestantesLicenca(lojaId: Number){
        return this.get(`${this.urlBloqueioController}/findDiasRestantesLicenca/${lojaId}`)
    }

}


